<template>
  <right-sidebar :display="display" @close="handleClose" :title="paymentSideBarTitle" @handleBack="handlePreviousStep" :showBack="handleShowBack">
    <template #body>
      <div class="payment-link-container-body relative">
        <p class="text-grey-2 text-sm" v-if="nextActiveStep === 'one' || nextActiveStep == 'two'">Fill beneficiary’s information</p>
        <!-- <keep-alive> -->
        <FirstStep :prefillbeneficiaryDetails="prefillbeneficiaryDetails" @resetBeneficiaryPreview="prefillbeneficiaryDetails = false" :wireAccountData="wireAccountData" @nextStep="handleNextStep" v-if="nextActiveStep === 'one'" :userData="userData"/>
        <second-step :makePaymentTxType="makePaymentTxType" :wireAccountData="wireAccountData" @nextStep="handleNextStep" v-if="nextActiveStep === 'two'" :userData="userData"/>
        <Summary :makePaymentTxType="makePaymentTxType" :editPaymentDetails="editPaymentDetails" :wireAccountData="wireAccountData" v-if="nextActiveStep === 'summary'" :userData="userData" @success="handleClose"/>
        <Beneficiary :wireAccountData="wireAccountData" v-if="nextActiveStep === 'beneficiary'" @beneficiary="handleBeneficiary"/>
      <!-- </keep-alive> -->
      <beneficiary-preview @edit="handleEdit" v-if="nextActiveStep === 'preview'" :userData="userData" @nextStep="handleNextStep({step: 'two'})"/>

        <!-- <ReviewRate v-if="nextActiveStep === 'reviewRate'" @success="handleClose"/> -->
      </div>
    </template>
  </right-sidebar>
</template>
<script>
  import { RightSidebar } from "@/UI/Modals";
  import { Input, Select, CurrencyInput } from "@/UI/Input";

  const defaultUserData = {
    beneficiary: {
          id: "",
          name: "",
          address: "",
          email: "",
          phoneNumber: "",
          country: "",
          accountName: "",
          accountNumber: "",
          intlBankId: "",
          bankName: "",
          swiftCode: "",
          postalCode:0,
          routingNumber: null,
          city:'',
          state:"",
          businessRegistrationNumber: null,
          financialSystemCode: null,
          countryCode:"",
          countrySpecificData: {
            purposeOfPaymentCodeDetails: null,
            acceptedCurrencies: [],
            collectBusinessRegistrationNumber: false,
            collectIfcs: false,
            collectIban: true,
            ibanExpectedLength: 0,
            countryCode:''
          }
    },
    purposeOfPayment: "",
    invoiceMediaId: [],
    receiveAmount: 0,
    receiveCurrency: "",
    fee: {
          currency: "",
          fee: 0
    },
    timer: null,
    minimumAmount: 1000,
    purposeOfPaymentCode: "",
    wirePaymentScheduleType: null,
    scheduledRate: 0,
    scheduledDate: null,
}
  export default {
    components: {
      RightSidebar,
      Input,
      Select,
      CurrencyInput,
      FirstStep: () => import("./FirstStep"),
      SecondStep:() => import('./SecondStep.vue'),
      Summary:() => import('./Summary'),
      Beneficiary:() => import('./Beneficiary.vue'),
      BeneficiaryPreview:() => import('./BeneficiaryPreview.vue')
      // ReviewRate:() => import('./ReviewRate')
    },
    props: {
      display: {
        type: Boolean,
        required: false,
        value: false,
      },
      externalBeneficiary:{
        type: Object,
        default(){
          return {}
        },
        required: false
      },
      wireAccountData:{
        type: Object,
        default:() => {}
      },
      editPaymentDetails:{
        type: Object,
        default: () => {}
      },
      makePaymentTxType:{
        type: String,
        default: '',
        required: false
      }
    },
    data:() => ({
        nextActiveStep: 'beneficiary',
        userData:defaultUserData,
        beneficiaryData:{},
        paymentNavigation:[],
        prefillbeneficiaryDetails: false
    }),
    computed:{
      handleShowBack(){
        return this.nextActiveStep !== 'beneficiary'
      },
      paymentSideBarTitle(){
        if(this.makePaymentTxType.length){
          if(this.makePaymentTxType == 'record'){
            return 'Record off wallet TX'
          }else if(this.makePaymentTxType == 'make'){
            return 'Make off wallet TX'
          }
        }
        return 'Make Payment'
      }
    },
    methods:{
        handleNextStep(value){
          this.nextActiveStep = value.step
            if(value.data){
              if(value.step === 'two'){
                this.userData.beneficiary = Object.assign({}, value.data)
              }else{
                this.userData = {...this.userData, ...value.data.beneficiary, ...value.data.scheduleData}
              }
            }
            this.handleNavigation(value.step)
          // }
        },
        handleBeneficiary(data){
          if(data === 'new'){
            // this.nextActiveStep = 'one'
            this.prefillbeneficiaryDetails = false
            this.handleNextStep({step: 'one'})
          }else{
         
            this.$nextTick(() => {
              this.beneficiaryData = data;
              this.userData.beneficiary = data
              if((data.countrySpecificData.collectIfcs && !data.financialSystemCode) 
              || (data.countrySpecificData.collectBusinessRegistrationNumber && (!data.businessRegistrationNumber || isNaN(data.businessRegistrationNumber) || data.businessRegistrationNumber.length != 10))
              || (data.countrySpecificData.collectIban && data.accountNumber.length < 15)
              || (!data.phoneNumber)
            ){
              this.prefillbeneficiaryDetails = true
              this.handleNextStep({step: 'one'})
              }else{
                this.handleNextStep({step: 'preview'})
              }
              this.$forceUpdate()
            })
          }
        },
        handlePreviousStep(){
          this.paymentNavigation.pop();
            const lastStep = this.paymentNavigation[this.paymentNavigation.length - 1];
            if(lastStep){
              if(lastStep == 'one'){
                this.prefillbeneficiaryDetails = true
              }
              this.nextActiveStep = lastStep
            }
        },
        handleClose(){
          this.$emit('close');
          this.$router.replace({
              ...this.$route.params,
              query:{}
          })
        },
        handleNavigation (value) {
          this.paymentNavigation.push(value);
          
        },
        handleEdit(){
          this.prefillbeneficiaryDetails = true;
          this.handleNextStep({step: 'one'})   
        },
        setPaymentDetailsForEdit(){
          Object.keys(this.editPaymentDetails).forEach((key) => {
            if(key == 'beneficiary' && this.editPaymentDetails.beneficiary){
              Object.keys(this.userData.beneficiary).forEach((beneficiaryData) => {
                // console.log(beneficiaryData, 'beneficiary data')
                if(this.userData.beneficiary.hasOwnProperty(beneficiaryData)) this.userData.beneficiary[beneficiaryData] = this.editPaymentDetails.beneficiary[beneficiaryData]
              })
            }
            
            else if(key === 'receiveCurrency'){
              this.userData[key] = this.editPaymentDetails.receiveCurrency.code
            }
            else if(this.userData.hasOwnProperty(key)){
              this.userData[key] = this.editPaymentDetails[key]
            }
        })
          this.prefillbeneficiaryDetails = true;
          this.handleNextStep({step: 'one'})
        }
    },
    mounted(){
      if(Object.keys(this.externalBeneficiary).length){
        this.userData.beneficiary = this.externalBeneficiary
        this.handleNextStep({step: 'two'}) 
      }else{
        this.handleNextStep({step: 'beneficiary'}) 
      }

      if(this.$route.query.edit_payment){
          this.setPaymentDetailsForEdit()
      }
      // if(this.makePaymentTxType.length){
      //   this.handleNextStep({step: 'one'}) 
      // }
      
    }
  };
</script>
<style scoped>
  .payment-link-container-body {
    padding: 16px 24px;
  }
</style>
